module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"QuranServer","short_name":"Quran","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"src/images/quran512.png","icons":[{"src":"src/images/quran16.png","type":"image/png","sizes":"16x16","purpose":"maskable"},{"src":"src/images/quran24.png","type":"image/png","sizes":"24x24","purpose":"maskable"},{"src":"src/images/quran32.png","type":"image/png","sizes":"32x32","purpose":"maskable"},{"src":"src/images/quran64.png","type":"image/png","sizes":"64x64","purpose":"maskable"},{"src":"src/images/quran128.png","type":"image/png","sizes":"144x144","purpose":"any"},{"src":"src/images/quran256.png","type":"image/png","sizes":"256x256","purpose":"maskable"},{"src":"src/images/quran512.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8ac3b32e91e46380d42e502576f46320"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"./src/custom-sw-code.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
